import { Measurement, MeasurementLookup } from './types'

type decodeMeasurementFunc = (input: number) => Measurement

const getAirTemperature = (input: number): Measurement => {
  return {
    Description: 'Air Temperature',
    Units: '°C',
    Value: input / 1000
  } as Measurement
}

const getRelativeHumidity = (input: number): Measurement => {
  return {
    Description: 'Relative Humidity',
    Units: '%',
    Value: input / 1000
  } as Measurement
}

const getCO2Concentration = (input: number): Measurement => {
  return {
    Description: 'CO2 Concentration',
    Units: 'ppm',
    Value: Math.floor(input / 1000)
  } as Measurement
}

const getBattery = (input: number): Measurement => {
  return {
    Description: 'Battery',
    Units: '%',
    Value: input % 256
  } as Measurement
}

const metricsLookup = {
  4097: getAirTemperature,
  4098: getRelativeHumidity,
  4100: getCO2Concentration,
  7: getBattery
} as {[key: number]: decodeMeasurementFunc}

const divideBy7Bytes = (str: string) => {
  const frameArray = [] as string[]
  for (let i = 0; i < str.length - 4; i += 14) {
    frameArray.push(str.substring(i, i + 14))
  }
  return frameArray
}

const littleEndianTransform = (data: string): string[] => {
  const dataArray = [] as string[]
  for (let i = 0; i < data.length; i += 2) {
    dataArray.push(data.substring(i, i + 2))
  }
  dataArray.reverse()
  return dataArray
}

const strTo10SysNub = (str: string): number => {
  return parseInt(littleEndianTransform(str).toString().replace(/,/g, ''), 16)
}

const toBinary = (arr: string[]): string => {
  const binaryData = [] as string[]
  for (const input of arr) {
    let data = parseInt(input, 16).toString(2)
    const dataLength = data.length
    if (data.length !== 8) {
      for (let i = 0; i < 8 - dataLength; i++) {
        data = '0' + data
      }
    }
    binaryData.push(data)
  }
  return binaryData.toString().replace(/,/g, '')
}

const processMeasurement = (str:string): number => {
  str = toBinary(littleEndianTransform(str))
  if (str.substring(0, 1) === '1') {
    const arr = str.split('')
    const reverseArr = [] as number[]
    for (const item of arr) {
      if (parseInt(item) === 1) {
        reverseArr.push(0)
      } else {
        reverseArr.push(1)
      }
    }
    return (parseInt('-' + reverseArr.join(''), 2) + 1)
  }
  return parseInt(str, 2)
}

export const Decode = (payload: string): MeasurementLookup => {
  const out = {} as MeasurementLookup
  if (payload.length < 18) {
    return out
  }
  payload = payload.substring(0, payload.length - 4)
  if (payload.length % 14 !== 0) {
    return out
  }
  const frameArray = divideBy7Bytes(payload)
  for (const frame of frameArray) {
    // Extract key parameters
    const dataID = strTo10SysNub(frame.substring(2, 6))
    // skip unknown metrics
    if (!(dataID in metricsLookup)) {
      continue
    }
    const dataValue = processMeasurement(frame.substring(6, 14))
    const m = metricsLookup[dataID](dataValue)
    if (m.Description) {
      out[m.Description] = m
    }
  }
  return out
}
